import React from 'react';

Noise.propTypes = {
    
};

function Noise(props) {
    return (
        <div className="noise">
            
        </div>
    );
}

export default Noise;